/* General Footer Styling */
footer {
    background-color: #000000;
    padding: 10px 0;
    color: #ffffff;
}

.container {
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 15px; 
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; 
}


.col-lg-4 {
    flex: 0 0 33.3333%; 
    max-width: 33.3333%;
    color: #ffffff;
}

/* Social Icons */
.left-text-content {
    text-align: left; 
}

.social-icons {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-start; 
    margin: 0;
}

.social-icons li {
    margin: 0 5px; 
}

.social-icons a {
    color: #ffffff;
    font-size: 20px;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #007bff;
}

/* Logo */
.logo {
    text-align: center; 
}

.logo img {
    max-width: 100%; 
    height: auto;
}


.right-text-content {
    text-align: right; 
}

/* Responsive Styles */
@media (max-width: 768px) {
    .row {
        flex-direction: column; 
        align-items: center; 
    }

    .col-lg-4 {
        flex: 0 0 100%; 
        max-width: 100%;
        text-align: center; 
        margin-bottom: 10px; 
    }

    .social-icons {
        justify-content: center; 
        flex-wrap: wrap;
    }

    .social-icons li {
        margin: 0 10px; 
    }

    .logo img {
        width: 60px; 
    }
}

@media (max-width: 480px) {
    .col-lg-4 {
        flex: 0 0 100%; 
        max-width: 100%;
        margin-bottom: 5px; 
    }

    .left-text-content,
    .logo,
    .right-text-content {
        text-align: center; 
    }

    .social-icons {
        flex-direction: row; 
        justify-content: center; 
    }

    .social-icons li {
        margin: 0 10px; 
    }
}

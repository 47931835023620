.slider-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}

.slick-slide img {
    width: 100%;
    height: auto; 
}

.prev-button,
.next-button {
    background-color: #fb5849;
    border: none;
    border-radius: 18%;
    color: #fff;
    font-size: 15px;
    height: 35px;
    line-height: 35px;
    opacity: .3;
    outline: none;
    position: absolute;
    text-align: center;
    top: 40%;
    transform: translateY(-50%);
    transition: all .3s;
    width: 38px;
    z-index: 5;
}

.prev-button {
    left: 50px;
}

.next-button {
    right: 50px;
}

/* FontAwesome icons */
.prev-button::before {
    content: '\f053';
    font-family: 'FontAwesome';
}

.next-button::before {
    content: '\f054'; 
    font-family: 'FontAwesome';
}

/* Hover effects */
.prev-button:hover,
.next-button:hover {
    opacity: 1;
    background-color: #fb5849;
    transform: translateY(-50%) scale(1.1);
}

/* Media queries for mobile screens */
@media (max-width: 768px) {
    .prev-button,
    .next-button {
        width: 40px;
        height: 40px;
        font-size: 24px;
        line-height: 38px;
    }
}

@media (max-width: 480px) {
    .prev-button,
    .next-button {
        width: 30px;
        height: 30px;
        font-size: 18px;
        line-height: 28px;
    }
    
    .prev-button {
        left: 5px;
    }

    .next-button {
        right: 5px;
    }
}

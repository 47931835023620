
.two-column-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 3px; 
  }
  
  /* General column styling */
  .column {
    flex: 1;
    padding: 10px;
  }
  
  /* Content column styling */
  .content-column {
    max-width: 50%;
    margin-right: 40px; 
    margin-left: 40px;  
  }
  
  /* Section heading styling */
  .section-heading h6 {
    position: relative;
    font-size: 15px;
    color: #fb5849;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: justify;
    padding-left: 40px;
  }
  
  .section-heading h6:before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px; 
    width: 30px; 
    height: 1px; 
    background-color: #fb5849; 
  }
  
  /* Section heading h2 styling */
  .section-heading h2 {
    text-align: left;
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 15px;
  }
  
  /* Content paragraph styling */
  .content-column p {
    font-size: 16px;
    line-height: 1.5;
    text-align: justify; 
  }
  
  /* Image column styling */
  .image-column {
    max-width: 40%;
    display: flex;
    justify-content: center;
    margin-left: 30px; 
    margin-right: 30px; 
  }
  
  .image-column img {
    max-width: 100%;
    height: auto;
    border-radius: 8px; 
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .two-column-section {
      flex-direction: column; 
      padding: 15px; 
    }
  
    .content-column,
    .image-column {
      max-width: 100%; 
      margin: 0; 
    }
  
    .image-column {
      margin-top: 5px; 
    }
    .thumb img {
      width: 100%; /* Make the image responsive */
      height: auto !important; /* Maintain aspect ratio */
    }
  }
  @media (max-width: 992px) {
    #about .right-content {
        margin-left: 0px;
        margin-bottom: 10px;
        margin-top: 3px !important;
    }
}